<template>
  <div class="date-picker-format">
    <label class="weight-600">Date Format</label>
    <input
      v-model="dateFormat"
      class="date-format hlt-input"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dateFormat: ''
    }
  },
  computed: {
    ...mapGetters(['asset'])
  },
  watch: {
    dateFormat (val, oldVal) {
      var json = JSON.parse(this.asset.choices[0].text)
      json.date_format = this.dateFormat

      this.asset.choices[0].raw_content = JSON.stringify(json)
      this.asset.choices[0].text = JSON.stringify(json)
    }
  },
  created () {
    this.dateFormat = JSON.parse(this.asset.choices[0].text).date_format
  }
}
</script>

<style lang="scss" scoped>
.date-format {
  width: 200px;
  padding: 10px;
}
</style>
