<template>
  <div>
    <div
      v-if="!orderObject.deleted"
      :id="anchorKey"
      class="order-box"
    >
      <!-- Don't display deleted because
          we're purposely not filtering them out for the following scenario:
          if you delete and then reorder, the reorder setters will resets the data to only the current active choices
          and then the deleted object won't get saved as deleted -->

      <div :class="[boxStyles.header, 'header']">
        <div class="left">
          <label class="header-title weight-600 montserrat">
            <slot name="headerTitle" />
          </label>
        </div>
        <div class="center">
          <div
            class="action-icons extras"
          >
            <slot name="headerExtras" />
          </div>
        </div>

        <div
          v-if="draftMode"
          class="right"
        >
          <!-- This will be added in the next version -->
          <!-- <div class="action-icons expand"> -->
          <!-- <img src="../../../assets/images/icons/expand.svg"> -->
          <!-- <img src="../../../assets/images/icons/minimize.svg"> -->
          <!-- </div> -->

          <div
            v-if="openAssetLink"
            class="action-icons popout"
          >
            <router-link
              :to="openAssetLink"
              target="_blank"
            >
              <open-file-svg />
            </router-link>
          </div>

          <div v-if="isMovable" class="ordering">
            <div
              v-if="isDraggable"
              class="action-icons order-box-drag"
            >
              <draggable-svg />
            </div>

            <div
              v-if="!isFirst && !isDraggable"
              class="action-icons up-section"
              @click="moveUp(orderObject, index)"
            >
              <up-svg />
            </div>
            <div
              v-if="!isLast && !isDraggable"
              class="action-icons down-section"
              @click="moveDown(orderObject, index)"
            >
              <down-svg />
            </div>
          </div>
          <div
            v-if="isDeletable"
            class="action-icons trash"
            @click="deleteOrderBox"
          >
            <x-svg />
          </div>
        </div>

        <div
          v-else
          class="right"
        >
          <div
            v-if="openAssetLink"
            class="action-icons popout"
          >
            <router-link
              :to="openAssetLink"
              target="_blank"
            >
              <open-file-svg />
            </router-link>
          </div>
        </div>
      </div>
      <div :class="[boxStyles.content, 'order-box-content']">
        <div>
          <slot name="content" />
        </div>
        <div class="fade-content-feature" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import OpenFileSvg from '@/assets/images/icons/open-file.svg'
import XSvg from '@/assets/images/icons/x.svg'
import DownSvg from '@/assets/images/icons/down-arrow.svg'
import UpSvg from '@/assets/images/icons/up-arrow.svg'
import DraggableSvg from '@/assets/images/icons/dark-up-down-arrow.svg'

export default {
  components: {
    OpenFileSvg,
    XSvg,
    DownSvg,
    UpSvg,
    DraggableSvg
  },
  props: {
    orderObject: {
      type: Object,
      required: true,
      default: () => ({
        deleted: true
      })
    },
    orderObjectType: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    orderObjectId: {
      type: Number
    },
    openAssetLink: {
      type: Object
    },
    isDraggable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isMovable: {
      type: Boolean,
      default: true
    },
    anchorKey: {
      type: String
    },
    draftMode: {
      type: Boolean
    },
    groupName: {
      type: String
    },
    groupSize: {
      type: Number
    },
    boxStyles: {
      type: Object,
      default: () => ({
        header: null,
        content: null
      })
    },
    sectionId: {
      type: Number
    },
    assetType: {
      type: String
    },
    deleteModalName: {
      type: String,
      default: 'delete-order-box-modal'
    }
  },
  computed: {
    ...mapGetters([
      'orderBoxReordering'
    ]),
    isFirst () {
      return this.index === 0
    },
    isLast () {
      return this.groupSize - 1 === this.index
    },
    ...mapGetters
  },
  methods: {
    ...mapMutations([
      'setOrderBoxReordering'
    ]),
    deleteOrderBox () {
      this.$modal.show(this.deleteModalName,
        {
          content: this.orderObject,
          type: this.orderObjectType,
          id: this.orderObjectId,
          index: this.index,
          groupName: this.groupName
        }
      )
    },
    moveDown (box, index) {
      if (!this.orderBoxReordering) {
        this.setOrderBoxReordering(true)
        this.$emit('move', {
          index,
          box,
          direction: 'down'
        })
      }
    },
    moveUp (box, index) {
      if (!this.orderBoxReordering) {
        this.setOrderBoxReordering(true)
        this.$emit('move', {
          index,
          box,
          direction: 'up'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-box {
  background: $white-2;
  margin-bottom: 30px;

  .section-asset-label {
    font-size: 12.8px;
    letter-spacing: .5px;
    margin: 0;
  }
  .header {
    padding-left: 10px;
    display: flex;
    border: 1px solid $space-gray;
    border-bottom: 0;
    overflow: hidden;
    padding-left: 10px;
    min-height: 30px;
    align-items: center;
    justify-content: space-around;
    .left {
      display: flex;
      flex-grow: 4;
      .header-title {
        color: $space-black;
        margin: 0;
      }
    }
    .right {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .action-icons {
        text-align: center;
        padding: 5px;
        width: 40px;
        border-left: 1px solid $space-gray;
        cursor: pointer;
      }
      .ordering {
        display: flex;
      }
    }
  }
  .no-padding {
    padding: 0 !important;
  }
  .shaded-background {
    background: $cloud-white;
  }

  .order-box-content {
    padding: 16px 40px 40px;
    border: 1px solid $space-gray;
    min-height: 100px;
    .fade-content-feature {
      display: none;
    }

    .height-restriction {
      max-height: 180px;
      overflow: hidden;
      .fade-content-feature {
        display: block;
      }
    }
    position: relative;
      .fade-content-feature {
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top,  rgba(247,247,247,0) 0%, rgba(247,247,247,1) 80%, rgba(247,247,247,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
        background: linear-gradient(to bottom,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
        border-radius: 0 0 4px 4px;
      }
  }
}
.add-asset-row, .loading-asset-section {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: .75rem 5rem;
  .asset-select {
    flex-grow: 1;
    margin-right: 1.5rem;
  }
  .asset-input {
    flex-grow: 1;
    margin-right: 1.5rem;
    padding-bottom: 10px;
  }
  .asset-submit {
    width: auto;
    margin-right: 1.5rem;
    padding-top: 32px;
    button {
      margin-bottom: 1.25rem;
      &:disabled {
        background: $green-1;
      }
    }
  }
}
</style>
