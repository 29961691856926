<template>
  <div class="section">
    <order-box
      :order-object="{ id: section }"
      order-object-type="Dropdown"
      :order-object-id="section"
      :index="section"
      :anchor-key="&quot;section-&quot; + section"
      :draft-mode="true"
      :is-draggable="false"
      :isMovable="false"
      :isDeletable="isDeletable"
      :group-size="section"
      delete-modal-name="delete-section-modal"
    >

      <template v-slot:headerTitle>
        Dropdown Identifier: {{ mustacheCode }}
      </template>

      <template v-slot:content>
        <draggable
          v-model="choices"
          handle=".order-box-drag"
          :group="choices"
          class="draggable choices"
        >
          <transition-group>
            <order-box
              v-for="(choice, index) in choices"
              :key="'choice-' + index"
              :order-object="choice"
              :order-object-id="choice.id"
              order-object-type="Choice"
              :index="index"
              :draft-mode="true"
              :isDeletable="isDeletable"
              :isMovable="isMovable"
              :groupName="`${section}`"
              delete-modal-name="delete-choice-modal"
            >
              <template v-slot:headerTitle>
                Choice {{ choice.id }}
              </template>

              <template v-slot:headerExtras>
                <div class="choice-key" v-if='asset.type != "MatrixGrid"'>
                  <hlt-toggle
                    class="choice-key-button"
                    :value="choice.correct"
                    :labels="{
                      checked: 'Key',
                      unchecked: 'Key'
                    }"
                    style-type="small"
                    @change="toggleCorrect(choice)"
                  />
                </div>
              </template>

              <template v-slot:content>
                <choice
                  :choice="choice"
                  :index="index"
                  :hideCSR="true"
                  :wysiwyg="false"
                />
              </template>
            </order-box>
          </transition-group>
        </draggable>
        <button
          v-if="canWriteToAsset"
          class="hlt-button secondary add-choice-button"
          @click="addChoice()"
        >
          Add Choice
        </button>
      </template>
    </order-box>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import Choice from './shared/Choice.vue'
import randomize from '@/mixins/randomize-choices.js'
import OrderBox from '../../../shared/OrderBox.vue'
import DeleteOrderBoxModal from '../../../shared/DeleteOrderBoxModal.vue'
import HltToggle from '../../shared/page_components/Toggle.vue'

export default {
  props: {
    section: {
      type: Number,
      required: true
    }
  },
  mixins: [randomize],
  components: {
    Choice,
    Draggable,
    OrderBox,
    DeleteOrderBoxModal,
    HltToggle
  },
  computed: {
    ...mapGetters([
      'asset',
      'canWriteToAsset',
      'assignment',
      'currentUser'
    ]),
    isMovable () {
      return !(this.assignment || !this.currentUser.permissions.can_write_flashcards)
    },
    isDeletable () {
      return !(this.assignment || !this.currentUser.permissions.can_write_flashcards)
    },
    mustacheCode () {
      return `{{ dropdown-${this.section} }}`
    },
    choices () {
      if (this.asset.choices) {
        // Don't filter out deleted because
        // if you then reorder, the setter resets choices to only the current active choices
        // and then the deleted choice won't get saved as deleted
        // Instead, OrderBox has a conditional to hide the deleted in the UI
        return this._.sortBy(this.asset.choices.filter(x => x.drop_down_identifier == this.section), ['sort_order'])
      } else {
        return []
      }
    }
  },
  methods: {
    addChoice () {
      let maxIndex = 0
      if (!this.randomized && this.asset.choices.length > 0) {
        maxIndex = Math.max.apply(Math, this.asset.choices.filter(x => x.drop_down_identifier == this.section).map((o) => { return o.sort_order }))
      }
      this.asset.choices.push({
        deleted: false,
        correct: false,
        sort_order: maxIndex + 1,
        drop_down_identifier: this.section
      })
    },
    toggleCorrect (choice) {
      if (this.canWriteToAsset) {
        if (!choice.correct) {
          for(let c of this.choices) {
            c.correct = false
          }
          choice.correct = true
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.section {
  margin-bottom: 20px;
}
</style>

<style lang="scss">
.section {
  .choices {
    .order-box-content {
      padding: 0px;
    }
  }
}
</style>