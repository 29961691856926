<template>
  <div>
    <h4>Column Header Names</h4>
    <div class="column-headers">
      <div class="column-header left-header">
        <label>Column 1</label>
        <input v-model="asset.matrix_grid_column_headers[0]" />
      </div>
      <div class="column-header">
        <label>Column 2</label>
        <input v-model="asset.matrix_grid_column_headers[1]" />
      </div>
    </div>
    <multiple-choice />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MultipleChoice from './MultipleChoice.vue'

export default {
  components: {
    MultipleChoice
  },
  computed: {
    ...mapGetters([
      'asset'
    ])
  }
}
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 600;
}
.column-headers {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  .column-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &.left-header {
      margin-right: 40px;
    }
    label {
      font-weight: 700;
    }
    input {
      flex-grow: 1;
      padding: 5px;
    }
  }
}
</style>