<template>
  <modal
    ref="deleteModalName"
    :name="deleteModalName"
    height="auto"
    :scrollable="false"
    class="vue-modal"
    width="45%"
    @before-open="setModalData"
  >
    <div
      v-if="content"
      class="vue-modal-inner"
    >
      <div class="vue-modal-topbar">
        <h4 class="overwrite-warning">
          Are you sure you want to delete {{ type }} - {{ id }}?
        </h4>
      </div>
      <div class="modal-container">
        <div>
          This is a change you can't undo.
        </div>
      </div>

      <div class="vue-modal-bottombar modal-buttons">
        <button
          class="hlt-button warning"
          @click="deleteContent()"
        >
          Delete
        </button>
        <button
          class="hlt-button default"
          @click="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    deleteModalName: {
      type: String,
      default: 'delete-order-box-modal'
    }
  },
  data () {
    return {
      content: null,
      type: null,
      id: null,
      index: null,
      groupName: null
    }
  },
  methods: {
    setModalData (event) {
      this.content = event.params.content
      this.type = event.params.type
      this.id = event.params.id
      this.index = event.params.index
      this.groupName = event.params.groupName
    },
    deleteContent () {
      this.$emit('orderBoxDeletion', { index: this.index, groupName: this.groupName, type: this.type })
      this.$modal.hide(this.deleteModalName)
    },
    cancel () {
      this.$modal.hide(this.deleteModalName)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-buttons {
  button {
    margin-left: 15px;
  }
}
</style>
