<template>
  <modal
    name="asset-tray-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal asset-tray-modal"
    width="45%"
    :click-to-close="false"
    @before-open="beforeOpen"
  >
    <div
      @click.stop=""
      v-on-clickaway="resetModal"
      class="vue-modal-inner"
    >
      <div class="vue-modal-topbar">
        <h4>
          Add Asset Tray
        </h4>
      </div>
      <div class="modal-container topic-id-container">
        <label>Asset Tray Live Topic ID:</label>
        <div>
          <input type="text" class="topic-id" v-model="assetTrayTopicId" :disabled="topics.length > 0" />
        </div>

      </div>
      <div class="asset-tray-details" v-if="tabs.length > 0 && activeTab">
        <div class="instructions">
          <label>Instructions</label>
          <ol>
            <li>Check the tabs you want to show to users.</li>
            <li>Check the sections under each tab you want a user to see.</li>
            <li>Select the circles on tabs to indicate to users where new content is presented.</li>
          </ol>
        </div>
        <div class="asset-tray-tabs">
          <div
            :class="['asset-tray-tab', {'active': tab.id == activeTab.id }]"
            v-for="tab in tabs"
            :key="'asset-tray-tab-' + tab.id"
            @click="changeActiveTab(tab)"
          >
            <div>
              <input type="checkbox" @click="toggleSelectedTopic(tab)" :checked="selectedTopicIds.includes(tab.id)" >
            </div>
            <div v-html="tab.title" />
            <div
              :class="['dot', {'active': dottedTabs.includes(tab.id)}]"
              @click="toggleDottedTabs(tab)"
            ></div>
          </div>
        </div>

        <div class="asset-tray-tab-content">
          <div class="subtopic" v-for="subtopic in tabTopics" :key="'asset-tray-content-' + subtopic.id">
            <div class="title">
              <div>
                <input type="checkbox" @click="toggleSelectedTopic(subtopic)" :checked="selectedTopicIds.includes(subtopic.id)" />
              </div>
              <div v-html="subtopic.title" />
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-bar">
        <div class="left">
          <button
            v-if="activeTab"
            class="hlt-button hlt-small-button delete-button warning"
            @click="confirmDelete()"
          >
            Delete
          </button>
        </div>
        <div class="right">
          <button
            class="button cancel-button modal-button secondary"
            @click="resetModal()"
          >
            Cancel
          </button>
          <button
            v-if="!activeTab"
            :class="['button', 'load-button', 'modal-button', 'secondary', { 'disabled': loading }]"
            @click="loadAssetTray()"
          >
            Load
          </button>
          <div v-else>
            <button
              :class="['button', 'solid-green-button', 'modal-button', 'save-button', { 'disabled': saving }]"
              @click="save()"
            >
              Save
            </button>          
          </div>
        </div>
      </div>
    </div>

  </modal>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import railsRoutes from '@/store/rails-routes'
import Spinner from '@/components/assets/shared/Spinner'

export default {
  mixins: [ clickaway ],
  components: {
    Spinner
  },
  data () {
    return {
      assetTrayTopicId: null,
      topics: [],
      activeTab: null,
      context: null,
      selectedTopicIds: [],
      dottedTabs: [],
      assetAssociation: null,
      saving: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'assetType'
    ]),
    rootTopic () {
      return this.topics.filter(x => x.parent_id == null)[0]
    },
    tabs() {
      return this._.orderBy(this.topics.filter(x => x.parent_id == this.rootTopic.id && !x.deleted), ['order'], ['asc'])
    },
    tabTopics () {
      return this._.orderBy(this.topics.filter(x => x.parent_id == this.activeTab.id && !x.deleted), ['order'], ['asc'])
    }
  },
  methods: {
    ...mapActions([
      'apiTopicHierachyById',
      'update'
    ]),
    ...mapMutations([
      'addAssetAssociation'
    ]),
    confirmDelete () {
      this.$confirm(
        {
          message: 'Are you sure?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              this.deleteAssetTray()
              this.update({
                asset: this.asset,
                url: railsRoutes.api_path({ type: 'items' })
              })
            }
          }
        }
      )
    },
    deleteAssetTray () {
      let selectedAssociation = this.assetAssociation
      let selectedAsset = this.asset

      const removedAssociationIndex = selectedAsset.asset_associations.indexOf(selectedAssociation)
      const removedAssociation = selectedAsset.asset_associations[removedAssociationIndex]
      if (removedAssociation) {
        removedAssociation.deleted = true
      }
      selectedAsset.asset_associations.splice(removedAssociationIndex, 1, removedAssociation)
      this.save()
    },
    toggleSelectedTopic(topic) {
      if(this.selectedTopicIds.includes(topic.id)) {
        this.selectedTopicIds.splice(this.selectedTopicIds.indexOf(topic.id), 1)
      } else {
        this.selectedTopicIds.push(topic.id)
      }
    },
    toggleDottedTabs(topic) {
      if(this.dottedTabs.includes(topic.id)) {
        this.dottedTabs.splice(this.dottedTabs.indexOf(topic.id), 1)
      } else {
        this.dottedTabs.push(topic.id)
      }
    },

    beforeOpen (event) {
      if (event && event.params) {
        this.resetModal()

        if (event.params.context) {
          this.context = event.params.context
        }
        if (event.params.assetAssociation) {
          // Use the topic id to fetch the whole tray structure
          this.assetAssociation = event.params.assetAssociation
          this.assetTrayTopicId = event.params.assetAssociation.asset_b_context // This should be the topic id
          this.loadAssetTray()

          // Fetch everything related to the asset tray grouping, tabs, and all content (asset associations to subtopics)
          axios.get(railsRoutes.api_path({ type: 'asset_tray', id:  event.params.assetAssociation.id })).then(response => {
            // Use response to mark what needs to be marked as 'selected'
            this.selectedTopicIds = response.data.topic_ids
            this.dottedTabs = response.data.dotted_tab_ids
          })
        }
      }
    },
    save () {
      if(!this.saving) {
        this.saving = true
        if(this.assetAssociation) {
          // do an update
          axios.put(railsRoutes.api_path({ type: 'asset_tray', id: this.assetAssociation.id }), {
            live_topic_id: this.assetTrayTopicId, // TODO: might be useful later to swap out the whole asset tray
            selected_topic_ids: this.selectedTopicIds, // This needs to come from the topics we have selected
            dotted_tab_ids: this.dottedTabs
          }).then(() => {
            this.resetModal()
          })
        } else {
          // send asset tray data to the asset tray controller
          axios.post(railsRoutes.api_path({ type: 'asset_tray' }), {
            asset_id: this.asset.id,
            asset_type: this.assetType,
            asset_context: this.context,
            live_topic_id: this.assetTrayTopicId,
            selected_topic_ids: this.selectedTopicIds, // This needs to come from the topics we have selected
            dotted_tab_ids: this.dottedTabs
          }).then(response => {
            this.addAssetAssociation({ association: response.data.asset_association })
            this.resetModal()
          })
        }
      }
    },
    changeActiveTab(tab) {
      this.activeTab = tab
    },
    resetModal () {
      this.topics = []
      this.selectedTopicIds = []
      this.dottedTabs = []
      this.activeTab = null
      this.context = null
      this.assetTrayTopicId = null
      this.saving = false
      this.$modal.hide('asset-tray-modal')
    },
    loadAssetTray () {
      this.loading = true
      this.apiTopicHierachyById({ topicId: this.assetTrayTopicId }).then(response => {
        this.topics = response.data.records
        this.activeTab = this.tabs[0]
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.asset-tray-modal {
  .vue-modal-topbar {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    color: $slate-gray;
  }
  .asset-tray-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;

    .instructions {
      font-size: .9em;
      margin-bottom: 10px;
    }

    input {
      margin: 0px;
      margin-right: 10px;
    }
    .asset-tray-tabs {
      display: flex;

      .asset-tray-tab {
        border-right: 1px solid $space-gray;
        border-left: 1px solid $space-gray;
        border-top: 1px solid $space-gray;
        border-bottom: 1px solid $space-gray;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        padding: 5px 15px;
        background: $space-gray-20;
        cursor: pointer;
        display: flex;
        align-items: center;
        &.active {
          background: $space-gray-50;
          border-bottom: none;
        }
        .dot {
          border-radius: 50%;
          border: 2px solid $space-gray;
          width: 15px;
          height: 15px;
          margin-left: 20px;
          cursor: pointer;
          
          &.active {
            background: red;
          }
        }
      }
    }
    .asset-tray-tab-content {
      padding: 20px;
      .subtopic {
        .title {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .right {
      display: flex;
    }
  }
  .topic-id-container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    label {
      font-weight: 500 !important;
      margin-right: 100px;
    }
    div {
      flex-grow: 1;
    }
  }
  button {
    padding: 6px 24px !important;
  }
  .button {
    border: none;
    font-size: .8em;
  }
  .load-button {
    border: 1px solid $cold-gray !important;
  }
  .cancel-button {
    background: $space-gray;
    border: 1px solid $slate-gray !important;
  }
  .save-button {
    border: 1px solid $apple-green;
    display: flex;
    justify-content: space-between;
    .spinner {
      width: 30px;
      height: 13px;
    }
    &:hover {
      background: $apple-green;
    }
  }
}
</style>