<template>
  <div class="fractional-numeric-entry">
    <label class="weight-600">Correct Answer</label>
    <input
      v-model="numerator"
      type="number"
      class="hlt-input numerator"
    >
    <div class="rule" />
    <input
      v-model="denominator"
      type="number"
      class="hlt-input denominator"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      numerator: '',
      denominator: ''
    }
  },
  computed: {
    ...mapGetters(['asset'])
  },
  watch: {
    numerator (val, oldVal) {
      var json = JSON.parse(this.asset.choices[0].text)
      json.numerator = val
      json.denominator = this.denominator
      this.asset.choices[0].raw_content = JSON.stringify(json)
      this.asset.choices[0].text = JSON.stringify(json)
    },
    denominator (val, oldVal) {
      var json = JSON.parse(this.asset.choices[0].text)
      json.numerator = this.numerator
      json.denominator = val
      this.asset.choices[0].raw_content = JSON.stringify(json)
      this.asset.choices[0].text = JSON.stringify(json)
    }
  },
  created () {
    this.numerator = JSON.parse(this.asset.choices[0].text).numerator
    this.denominator = JSON.parse(this.asset.choices[0].text).denominator
  }
}
</script>
<style lang="scss" scoped>
.fractional-numeric-entry {
  .rule {
    width: 20%;
    height: 1px;
    border-top: 1px solid $space-gray;
    margin-top: 9px;
    margin-bottom: 10px;
  }
  input {
    width: 20%;
    margin: 0;
  }
}
</style>
