<template>
  <div
    v-if="asset"
    class="stem-stimulus"
  >
      <label class="weight-700">Stem</label>
    <div class="stem-stimulus-container">
      <div
        class="content"
        :class="[{active: editingQuestion}, 'stem-content-section']"
        @click.prevent="clickedQuestion"
      >
        <div class="question">
          <div
            v-if="editingQuestion"
            class="editing"
          >
            <tinymce-editor
              ref="question-editor"
              v-model="asset.question"
              :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], autoresize_bottom_margin: 10}, tinymceFullConfig)"
              use-custom-image-handler
              v-on-clickaway="previewQuestion"
              @onInit="setCaretPosition"
            />
          </div>
          <div
            v-if="!editingQuestion"
            class="preview"
            :class="{'error': errors['question'] }"
          >
            <div v-html="asset.question" />
            <error-message
              v-if="errors[&quot;question&quot;]"
              message="can't be blank"
            />
          </div>
        </div>
      </div>
      <asset-associations-container
        human-context="Stimulus"
        context="question"
      />
    </div>
  </div>
</template>

<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import { mixin as clickaway } from 'vue-clickaway2'
import tinymce from '../../../../../mixins/tinymce.js'
import AssetAssociationsContainer from './AssetAssociationsContainer.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TinymceEditor,
    AssetAssociationsContainer
  },
  mixins: [clickaway, tinymce],
  props: ['asset'],
  data () {
    return {
      editingQuestion: false
    }
  },
  computed: {
    ...mapGetters([
      'errors',
      'canWriteToAsset',
      'currentUser'
    ])
  },
  methods: {
    clickedQuestion (ev) {
      if (this.canWriteToAsset) {
        this.questionToggle(ev)
      }
    },
    questionToggle (ev) {
      if (this.$refs['question-editor']) {
        if (!ev) return
        if (ev.target.className.includes('mce') ||
          ev.target.id.includes('mce')) {
          return
        }
      }
      this.editingQuestion = !this.editingQuestion
      this.setCharPosition(ev, 'question-editor')
    },

    previewQuestion (ev) {
      if (!this.insideTinyMCE(ev, 'question-editor')) {
        this.editingQuestion = false
      }
    }
  }
}
</script>

<style lang="scss">
.stem-stimulus {
  margin-bottom: 25px;
  .stem-stimulus-container {
    border: 1px solid $space-gray;
    .stem-content-section {
      border-radius: 4px;
      .preview {
        padding: 11px 12px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
