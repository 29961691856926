<template>
  <div class="ordered-response">
    <div class="tabs">
      <div class="tab-container">
        <div class="tab">
          <input
            id="tab-3"
            type="radio"
            name="tab-group-2"
            checked
          >
          <label
            id="tab-3-label"
            for="tab-3"
            class="foil-tab"
            @click="showTab('foil')"
          >Foil Order</label>
        </div>
        <div class="tab">
          <input
            id="tab-4"
            type="radio"
            name="tab-group-2"
          >
          <label
            id="tab-4-label"
            for="tab-4"
            class="correct-order-tab"
            @click="showTab('correct')"
          >Correct Order</label>
        </div>
      </div>

      <div
        v-if="!showCorrectOrder"
        class="questions"
      >
      <div
        class="toggle-wrapper"
      >
        <h4 class="hlt-header h4 weight-600">Choices</h4>
        <hlt-toggle
          v-if="asset.type != 'MatrixGrid'"
          class="randomize-button"
          style-type="medium"
          :value="randomized"
          :labels="{
            checked: 'Randomize',
            unchecked: 'Randomize'
          }"
          @change="canWriteToAsset && randomizeChoices('sort_order')"
          />
      </div>

        <draggable
          v-model="foilOrderChoices"
          handle=".order-box-drag"
          :group="foilOrderChoices"
          class="draggable"
        >
          <transition-group>
            <order-box
              v-for="(choice, index) in foilOrderChoices"
              :key="'choice-' + index"
              :order-object="choice"
              :order-object-id="choice.id"
              order-object-type="Choice"
              :index="index"
              :draft-mode="true"
              :box-styles="boxStyles"
              group-name="foilOrderChoices"
            >
              <template v-slot:headerTitle>
                Choice {{ choice.id }}
              </template>

              <template v-slot:content>
                <choice
                  :choice="choice"
                  :index="index"
                />
              </template>
            </order-box>
          </transition-group>
        </draggable>

        <button
          class="hlt-button secondary add_choice_button"
          @click="addChoice()"
        >
          Add Choice
        </button>
      </div>

      <div
        v-if="showCorrectOrder"
        class="questions"
      >
        <draggable
          v-model="correctOrderChoices"
          handle=".order-box-drag"
          :group="correctOrderChoices"
          class="draggable"
        >
          <transition-group>
            <order-box
              v-for="(choice, index) in correctOrderChoices"
              :key="'choice-' + index"
              :order-object="choice"
              :order-object-id="choice.id"
              order-object-type="Choice"
              :index="index"
              :draft-mode="true"
              :box-styles="boxStyles"
              group-name="correctOrderChoices"
            >
              <template v-slot:headerTitle>
                Choice {{ choice.id }}
              </template>

              <template v-slot:content>
                <choice
                  :choice="choice"
                  :index="index"
                />
              </template>
            </order-box>
          </transition-group>
        </draggable>

        <button
          class="hlt-button secondary add_choice_button"
          @click="addChoice()"
        >
          Add Choice
        </button>
      </div>

      <delete-order-box-modal @orderBoxDeletion="deleteChoice" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import Choice from './shared/Choice.vue'
import randomize from '@/mixins/randomize-choices.js'
import OrderBox from '@/components/shared/OrderBox.vue'
import DeleteOrderBoxModal from '@/components/shared/DeleteOrderBoxModal.vue'
import HltToggle from '@/components/assets/shared/page_components/Toggle.vue'

export default {
  components: {
    Choice,
    Draggable,
    OrderBox,
    DeleteOrderBoxModal,
    HltToggle
  },
  mixins: [randomize],
  data () {
    return {
      showCorrectOrder: false,
      boxStyles: {
        header: [],
        content: ['no-padding']
      }
    }
  },
  computed: {
    foilOrderChoices: {
      get () {
        if (this.asset.choices) {
          const foiledChoices = this.asset.choices
          // TODO: DONT FILTER OUT DELETED
          return this._.orderBy(foiledChoices.filter(k => !k.deleted), ['sort_order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.choices = this.orderArray(value, 'sort_order')
      }
    },
    correctOrderChoices: {
      get () {
        if (this.asset.choices) {
          return this._.orderBy(this.asset.choices.filter(k => !k.deleted), ['correct_order'], ['asc'])
        } else {
          return []
        }
      },
      set (value) {
        this.asset.choices = this.orderArray(value, 'correct_order')
      }
    },
    ...mapGetters([
      'asset',
      'canWriteToAsset'
    ])
  },
  created () {
    this.setRandomize()
  },
  methods: {
    showTab (val) {
      this.showCorrectOrder = (val == 'correct')
    },
    addChoice () {
      var correctOrder = 1
      var sortOrder = 1
      if (this.asset.choices.length > 0) {
        correctOrder = correctOrder + Math.max(...(this.asset.choices.map(c => c.correct_order)))
        sortOrder = sortOrder + Math.max(...(this.asset.choices.map(c => c.sort_order)))
      }
      this.asset.choices.push({ app_id: this.asset.app_id, deleted: false, correct: false, sort_order: sortOrder, correct_order: correctOrder })
    },
    deleteChoice (params) {
      let localChoices
      if (params.groupName == 'foilOrderChoices') {
        localChoices = this.foilOrderChoices
        localChoices[params.index].deleted = true
        this.foilOrderChoices = localChoices
      } else if (params.groupName == 'correctOrderChoices') {
        localChoices = this.correctOrderChoices
        localChoices[params.index].deleted = true
        this.correctOrderChoices = localChoices
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ordered-response {
  .tabs {
    min-height: 100px;
    /* This part sucks */
    margin: 25px 0;
    border: none !important;

    .active {
      border: 1px solid $blue-4;
      border-radius: 6px;
      box-shadow: 0px 0px 3px 3px $drop-shadow-blue;

      .add-asset-associations {
        border: 0px !important;
      }
    }

    .content {
      background: white;
      color: $blue-2;
      margin-top: 30px;

      .preview {
        padding: 20px;
        border: 1px solid $grey-3;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 0px;
      }
    }

    .tab {
      height: 36px;
      label {
        padding: 5px 15px;
        margin: 0;
        font-size: 85%;
        font-weight: 600;
        border: 1px solid $space-gray;
        color: $space-black-20;
        &.foil-tab {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right-style: none;
        }
        &.correct-order-tab {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left-style: none;
        }
      }

      [type=radio] {
        display: none;
      }

      [type=radio]:checked~label {
        background: $cold-gray;
        z-index: 2;
        color: white;
      }
    }
  }

  padding-top: 10px;
  .preview {
    border: 0px;
  }

  .tab-container {
    min-height: 30px;
    width: 100%;
    height: 30px;
    margin-bottom: 16px;
    display: flex;
  }
}
</style>
