<template>
  <div
    v-if="asset !== {}"
    class="add-asset-associations"
  >
    <div class="associations-button-section">
      <div class="main-button-section">
        <button
          v-if="canWriteToAsset"
          class="hlt-button secondary hlt-tiny-button add-association-button"
          @click="showModal"
        >
          Add {{ humanContext }}
        </button>
        <asset-associations
          :prop-asset.sync="asset"
          :context="context"
          :human-context="humanContext"
          :available-contexts="[context]"
        />
      </div>
      <change-request-button
        v-if="assignment"
        :asset-id="assignment.asset_id"
        :asset-type="assignment.asset_type"
        :context="context"
        :original="assignment.asset[context]"
      />
    </div>
    <div
      v-if="canWriteToAsset"
      :class="['asset-tray-container', { 'edit-asset-tray-button': hasAssetTray }]"
    >
      <button
        class="hlt-button hlt-tiny-button secondary"
        @click="showAssetTrayModal"
        v-if="hasAssetTray"
      >
        Edit Asset Tray
      </button>

      <button
        class="hlt-button hlt-tiny-button secondary"
        @click="showAssetTrayModal"
        v-else
      >
        Add Asset Tray
      </button>

    </div>
    <asset-tray-modal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AssetAssociations from '../../../shared/AssetAssociations.vue'
import ChangeRequestButton from '../../../shared/page_components/ChangeRequestButton'
import AssetTrayModal from '@/components/assets/shared/AssetTrayModal.vue'

export default {
  components: {
    AssetAssociations,
    ChangeRequestButton,
    AssetTrayModal
  },
  props: [
    'humanContext',
    'context'
  ],
  computed: {
    ...mapGetters([
      'canWriteToAsset',
      'asset',
      'assignment',
      'assetType'
    ]),
    hasAssetTray () {
      if(this.asset.id) {
        let assetTrays = this.asset.asset_associations.filter(x => {
          return !x.deleted && 
                  x.asset_a_context == this.context &&
                  x.asset_b_type == 'Grouping' &&
                  x.asset_b_data.type == 'Asset Tray'
        })
        return assetTrays.length == 1
      } else {
        return false
      }
    }
  },
  methods: {
    showAssetTrayModal () {
      let assetAssociation = this.asset.asset_associations.filter(x => {
        return x.asset_a_context == this.context && 
               x.asset_b_type == 'Grouping' &&
               x.asset_b_data.type == 'Asset Tray' &&
               !x.deleted
      })[0]
      this.$modal.show('asset-tray-modal', { context: this.context, assetAssociation: assetAssociation })
    },
    showModal () {
      this.$modal.show('add-asset-association-modal', {
        assetAssociation: {
          asset_a_id: this.asset.id,
          asset_a_type: 'V3Flashcard',
          asset_a_context: this.context,
          asset_b_id: '',
          asset_b_type: '',
          asset_b_context: '',
          button_text: '',
          order: '',
          display_type: ''
        },
        humanContext: this.humanContext,
        saveText: 'Add',
        editText: 'Add'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.add-asset-associations {
  width: 100%;
  display: flex;
  background-color: $cloud-white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-height: 45px;
  border-top: 1px solid $space-gray-20;
  .associations-button-section {
    display: flex;
    flex: 1;
    padding: 12px;
    .main-button-section {
      flex: 4;
      display: flex;
    }
    .add-association-button {
      margin-right: 15px;
      line-height: 1.1;
      max-width: 100%;
    }
  }
  .asset-tray-container {
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    border-left: 1px solid $space-gray-20;
    border-top: 1px solid $space-gray-20;
    background: $space-gray-30;
    &.edit-asset-tray-button {
      button {
        background: $sky-blue;
        color: white;
      }
    }
  }
}
</style>
