<template>
  <div>
    <div
      v-for="(context, i) in availableContexts"
      :key="'context' + i"
      :context="context"
    >
      <div
        v-if="assetAssociations(context).length > 0"
        class="asset-associations-row row"
        :class="{ 'space-contexts' : showContextNames }"
      >
        <transition-group name="fade">
          <div
            v-if="availableContexts && showContextNames"
            :key="'context-' + context"
            class="context-title"
          >
            {{ context }}
          </div>
          <div
            :key="&quot;asset-association-buttons-container&quot; + i"
            class="asset-association-buttons-container"
          >
            <div
              v-for="(association, j) in assetAssociations(context)"
              :key="'asset-association-' + j"
              :association="association"
              :index="j"
            >
              <div class="asset-association">
                <div
                  class="asset-association-id"
                  @click="editAssociation(context, j)"
                >
                  {{ assetTypeLetter(association.asset_b_type) }}{{ association.asset_b_id }}
                </div>
                <button
                  v-if="draftMode && canWriteToAsset"
                  class="remove-association"
                  @click="removeAssociation(association)"
                >
                  <x-svg class="remove-x" />
                </button>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import XSvg from '@/assets/images/icons/x-solid.svg'

export default {
  components: {
    XSvg
  },
  props: [
    'propAsset',
    'availableContexts',
    'propContext',
    'showContextNames'
  ],
  data () {
    return {
      selectedAssetType: '',
      selectedButtonText: '',
      assetID: '',
      selectedAssociation: null
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'asset',
      'draftMode',
      'asset',
      'canWriteToAsset'
    ])
  },
  methods: {
    assetAssociations (context) {
      let localAsset = null
      if (this.$router.isRoute('topic')) {
        localAsset = this.topics.filter(x => x.id === this.propAsset.id)[0]
      } else {
        localAsset = this.asset
      }
      if (localAsset && localAsset.asset_associations != undefined) {
        if (context == 'Primary Action') { // Hack for original null context for primary action button
          return localAsset.asset_associations.filter(k => k && !k.deleted && (k.asset_a_context == null || k.asset_a_context == context))
        } else {
          return localAsset.asset_associations.filter(k => {
            return k && 
                   !k.deleted &&
                   k.asset_a_context == context &&
                   (
                      k.asset_b_type != 'Grouping' ||
                      k.asset_b_data.type != 'Asset Tray'
                   )
          })
        }
      } else {
        return []
      }
    },

    editAssociation (context, index) {
      const association = this.assetAssociations(context)[index]

      let contexts = null
      if (this.availableContexts.length > 1) {
        contexts = this.availableContexts
      }

      if (this.$modal) {
        this.$modal.show('add-asset-association-modal', {
          assetAssociation: association,
          availableContexts: contexts,
          editText: 'Edit',
          saveText: 'Save',
          editing: true
        })
      }
    },
    removeAssociation (selectedAssociation) {
      let selectedAsset = null
      if (this.$router.isRoute('topic')) {
        selectedAsset = this.topics.filter(k => k.id == this.propAsset.id)[0]
      } else {
        selectedAsset = this.asset
      }
      const removedAssociationIndex = selectedAsset.asset_associations.indexOf(selectedAssociation)
      const removedAssociation = selectedAsset.asset_associations[removedAssociationIndex]
      if (removedAssociation) {
        removedAssociation.deleted = true
      }
      selectedAsset.asset_associations.splice(removedAssociationIndex, 1, removedAssociation)
    },
    assetTypeLetter (assetType) {
      var option = {
        Attachment: 'A',
        Topic: 'T',
        V3Flashcard: 'I'
      }
      return option[assetType]
    }
  }
}

</script>

<style lang="scss" scoped>
.asset-associations-row {
  margin-left: 0px !important;
  margin-bottom: 0px !important;

  .context-title {
    font-size: 90%;
  }
  .asset-association-buttons-container {
    padding-left: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.space-contexts {
  margin-bottom: 10px;
}
.asset-association {
  background: $white-2;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 10px;
  min-height: 20px;
  color: $space-black;
  padding-left: 10px;
  padding-right: 5px;
  border: 1px solid $space-gray;
  letter-spacing: 0.03em;
  font-weight: $font-weight-semi-bold;
  display: flex;
  align-items: center;

  &:first-child {
    margin-left: 0px !important;
  }
  .asset-association-id {
    display: inline-block;
    padding-right: 5px;
  }

  .asset-association-id:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .remove-association {
    &:hover {
      background: $space-gray-20;
      cursor: pointer;
    }
    .remove-x {
      height: 15px; // Is this even an svg?
    }
  }

  button {
    font-weight: 600;
    font-size: 1.2em;
    padding: 2px;
  }

  button:hover {
    border-radius: 4px;
  }
}
.primary-action-tag .asset-association {
  margin: 0;
}
</style>
