<template>
  <div>
    <div
      class="toggle-wrapper"
    >
      <h4 class="hlt-header h4 weight-600">Choices</h4>
      <hlt-toggle
        class="randomize-button"
        style-type="medium"
        :value="randomized"
        :labels="{
          checked: 'Randomize',
          unchecked: 'Randomize'
        }"
        @change="canWriteToAsset && randomizeChoices('sort_order')"
        />
    </div>
    <bowtie-section
      v-for="section in sections"
      :section="section"
    />
    <delete-order-box-modal
      delete-modal-name="delete-choice-modal"
      @orderBoxDeletion="deleteChoice"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BowtieSection from './BowtieSection.vue'
import HltToggle from '@/components/assets/shared/page_components/Toggle.vue'
import randomize from '@/mixins/randomize-choices.js'
import DeleteOrderBoxModal from '@/components/shared/DeleteOrderBoxModal.vue'

export default {
  components: {
    BowtieSection,
    HltToggle,
    DeleteOrderBoxModal
  },
  mixins: [randomize],
  computed: {
    ...mapGetters([
      'asset',
      'canWriteToAsset'
    ]),
    sections () {
      let sectionIds = this.asset.choices.filter(x => !x.deleted).map(x => x.drop_down_identifier)
      let uniqSectionIds = sectionIds.filter((v, i, a) => a.indexOf(v) === i)
      return uniqSectionIds.sort((a, b) => { return a - b })
    },
    choices: {
      get () {
        if (this.asset.choices) {
          // Don't filter out deleted because
          // if you then reorder, the setter resets choices to only the current active choices
          // and then the deleted choice won't get saved as deleted
          // Instead, OrderBox has a conditional to hide the deleted in the UI
          return this._.sortBy(this.asset.choices, ['sort_order'])
        } else {
          return []
        }
      },
      set (value) {
        if (this.canWriteToAsset) {
          this.asset.choices = this.orderArray(value, 'sort_order')
        }
      }
    }
  },
  methods: {
    deleteChoice (params) {
      let localChoices = this.choices.filter(x => x.drop_down_identifier == parseInt(params.groupName))
      if (localChoices[params.index].id === undefined) {
        localChoices.splice(params.index, 1)
      } else {
        localChoices[params.index].deleted = true
      }
    },
  },
  created () {
    this.setRandomize()
  }
}
</script>