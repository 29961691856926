<template>
  <div
    v-if="asset"
    class="explanation"
  >
    <div class="explanation-container">
      <label class="weight-700">Explanation
        </label>
      <div
        v-on-clickaway="previewExplanation"
        :class="[{active: editingExplanation}, 'explanation-content-section']"
      >
        <div
          class="explanation-main-content"
          @click.prevent="clickedExplanation"
        >
          <div
            v-if="editingExplanation"
            class="editing"
          >
            <tinymce-editor
              ref="explanation-editor"
              v-model="asset.rationale"
              :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize'], autoresize_bottom_margin: 10}, tinymceFullConfig)"
              use-custom-image-handler
              @onInit="setCaretPosition"
            />
          </div>
          <div
            v-if="!editingExplanation"
            class="preview"
            v-html="asset.rationale || 'Enter Explanation'"
          />
        </div>
        <asset-associations-container
          human-context="Remediation"
          context="rationale"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import { mixin as clickaway } from 'vue-clickaway2'
import tinymce from '../../../../../mixins/tinymce.js'
import AssetAssociationsContainer from './AssetAssociationsContainer.vue'
import SimpleTextBox from '@/components/assets/items/item_types/shared/SimpleTextBox.vue'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    TinymceEditor,
    AssetAssociationsContainer,
    SimpleTextBox
  },
  mixins: [clickaway, tinymce],
  props: ['asset'],
  data () {
    return {
      showExplanation: false,
      editingExplanation: false,
      enabledImproveButton: true,
      improvedRationale: null
    }
  },
  computed: {
    ...mapGetters([
      'errors',
      'canWriteToAsset',
      'currentUser'
    ])
  },
  methods: {
    clickedExplanation (ev) {
      if (this.canWriteToAsset) {
        this.explanationToggle(ev)
      }
    },
    explanationToggle (ev) {
      if (this.$refs['explanation-editor']) {
        if (!ev) return
        if (ev.target.className.includes('mce') ||
          ev.target.id.includes('mce')) {
          return
        }
      }
      this.editingExplanation = !this.editingExplanation
      this.setCharPosition(ev, 'explanation-editor')
    },
    previewExplanation (ev) {
      if (!this.insideTinyMCE(ev, 'explanation-editor')) {
        this.editingExplanation = false
      }
    },
  }
}
</script>

<style lang="scss">
.explanation {
  margin: 30px 0;
  .explanation-content-section {
    border: 1px solid $space-gray;
    .preview {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
