export default {
  data () {
    return {
      randomized: false
    }
  },
  methods: {
    orderArray (choices, order) {
      if (this.randomized) {
        for (const i in choices) {
          choices[i][order] = 1
          // TODO: Once the randomized_sort_order boolean is used in clients we can stop setting all orders to 1
        }
      } else {
        for (const i in choices) {
          choices[i][order] = parseInt(i)
        }
      }
      return choices
    },
    setRandomize () {
      this.randomized = this.asset.randomized_sort_order
    },
    randomizeChoices(order) {
      this.randomized = !this.randomized
      this.asset.choices = this.orderArray(
        this.asset.choices.filter(k => !k.deleted),
        order
      )
      if (order == 'sort_order') {
        this.asset.randomized_sort_order = this.randomized
      }
    }
  }
}
