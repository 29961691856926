var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showButton)?_c('button',{class:[
    {modified: _vm.requestButtonText === 'Modify Draft'},
    {pending: _vm.requestButtonText === 'Pending Review'},
    {approved: _vm.requestButtonText === 'Approved'},
    {rejected: _vm.requestButtonText === 'Rejected'},
    {'cursor-arrow': _vm.showCursorArrow},
    'hlt-button',
    'functional',
    'hlt-tiny-button',
    'change-request-button'
  ],on:{"click":function($event){$event.preventDefault();return _vm.clickButton.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.requestButtonText)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }