<template>
    <button
      :class="[
        {modified: requestButtonText === 'Modify Draft'},
        {pending: requestButtonText === 'Pending Review'},
        {approved: requestButtonText === 'Approved'},
        {rejected: requestButtonText === 'Rejected'},
        {'cursor-arrow': showCursorArrow},
        'hlt-button',
        'functional',
        'hlt-tiny-button',
        'change-request-button'
      ]"
      @click.prevent="clickButton"
      v-if="showButton"
    >
      {{ requestButtonText }}
    </button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    assetId: {
      type: Number,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    context: {
      type: String,
      required: true
    },
    original: {
      type: String,
      required: true
    },
    wysiwyg: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'assignment',
      'changeRequest',
      'currentUser'
    ]),
    showCursorArrow () {
      if (this.currentUser.permissions.is_a_copy_writer) {
        return this.requestButtonText === 'Pending Review'
      }
      return false
    },
    showButton () {
      if (this.assignment) {
        if (this.currentUser.permissions.is_a_copy_editor || this.currentUser.permissions.is_a_asset_assigner) {
          if ((this.assignment.state === 'submitted') || (this.assignment.state === 'completed') || (this.assignment.state == 'rejected')) {
            const request = this.changeRequest(this.assetId, this.assetType, this.context)
            if (request) {
              return true
            }
          } else {
            return false
          }
        } else if (this.currentUser.permissions.is_a_copy_writer) {
          if ((this.assignment.state === 'submitted') || (this.assignment.state === 'completed')) {
            const request = this.changeRequest(this.assetId, this.assetType, this.context)
            if (request) {
              return true
            }
          } else {
            return true
          }
        }
      }
      return false
    },
    newChangeRequest () {
      const request = this.changeRequest(this.assetId, this.assetType, this.context)
      return !request || request?.requested_change === this.original
    },
    requestButtonText () {
      if (this.assignment) {
        if (this.assignment.state === 'assigned') {
          const cr = this.changeRequest(this.assetId, this.assetType, this.context)
          if (cr) {
            if (cr.state === 'modified') {
              return 'Modify Draft'
            } else {
              return 'Draft'
            }
          }
          if (this.newChangeRequest) {
            return 'Draft'
          } else {
            return 'Modify Draft'
          }
        } else if (this.assignment.state === 'submitted' || this.assignment.state === 'completed') {
          const cr = this.changeRequest(this.assetId, this.assetType, this.context)
          if (cr) {
            switch (cr.state) {
              case 'pending': return 'Pending Review'
              case 'approved': return 'Approved'
              case 'rejected': return 'Rejected'
            }
          }
        } else if (this.assignment.state === 'rejected') {
          const cr = this.changeRequest(this.assetId, this.assetType, this.context)
          if (cr) {
            switch (cr.state) {
              case 'pending': return 'Pending Review'
              case 'approved': return 'Approved'
              case 'rejected': return 'Rejected'
              case 'modified': return 'Modify Draft'
            }
          }
          return 'Draft'
        }
      }
      return ''
    }
  },
  methods: {
    clickButton () {
      if (this.currentUser.permissions.is_a_copy_editor || this.currentUser.permissions.is_a_asset_assigner) {
        this.showModal()
      } else if (this.currentUser.permissions.is_a_copy_writer) {
        if (this.assignment.state === 'assigned' || this.assignment.state === 'rejected') {
          this.showModal()
        }
      }
    },
    showModal () {
      this.$modal.show('asset-changes-modal', {
        assetId: this.assetId,
        assetType: this.assetType,
        context: this.context,
        original: this.original,
        wysiwyg: this.wysiwyg
      })
    }
  }

}
</script>

<style lang="scss">
.change-request-button {
  &.modified {
    background: $space-gray;
    color: $white-2;
    border: 1px solid $space-gray;
    &:hover {
      background: $space-gray-10;
    }
  }
  &.pending {
    background: $orange-1;
    color: $white-2;
    border: 1px solid $orange-1;
    &:hover {
      background: $orange-1;
    }
  }
  &.rejected {
    background: $red-1;
    color: $white-2;
    border: 1px solid $red-1;
    &:hover {
      background: lighten($red-1, 10%);
    }
  }
  &.approved {
    background: $pastel-green;
    color: $white-2;
    border: 1px solid $apple-green;
    &:hover {
      background: $apple-green;
    }
  }
  .cursor-arrow {
    cursor: default;
  }
}
</style>
