<template>
  <div class="flipcards-container">
    <div class="flipcard-section">
      <div
        class="flipcard-front"
        @click="clickedFront"
      >
        <span class="flipcard-label">Front Card</span>
        <div
          class="flipcard"
          :class="{'selected': editingFrontCard === true}"
        >
          <div
            v-if="!editingFrontCard"
            class="front-card-section"
            :class="{'error': errors['question'] }"
          >
            <div
              class="flip-question"
              v-html="asset.question"
            />
          </div>

          <div
            v-if="editingFrontCard"
            class="flipcard-edit flip-front"
          >
            <tinymce-editor
              ref="front-card-editor"
              v-model="asset.question"
              v-on-clickaway="frontCardToggle"
              :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
              name="title"
              @onInit="setCaretPosition"
            />
          </div>
        </div>
        <error-message
          v-if="errors[&quot;question&quot;]"
          message="can't be blank"
        />
      </div>
      <div
        class="flipcard-back"
        @click="clickedBack"
      >
        <span class="flipcard-label">Back Card</span>
        <div
          class="flipcard"
          :class="{'selected': editingBackCard === true}"
        >
          <div
            v-if="!editingBackCard"
            class="back-card-section"
            :class="{'error': errors['answers[0].raw_content'] }"
          >
            <div
              class="flip-back-content"
              v-html="asset.choices[0].raw_content"
            />
          </div>
          <div
            v-if="editingBackCard"
            class="flipcard-edit flip-back"
          >
            <tinymce-editor
              ref="back-card-editor"
              v-model="asset.choices[0].raw_content"
              v-on-clickaway="backCardToggle"
              :init="tinymceConfig({plugins: ['code', 'autoresize','table', 'lists', 'image'], forced_root_block : ''}, tinymceFullConfig)"
              name="title"
              @onInit="setCaretPosition"
            />
          </div>
        </div>
        <error-message
          v-if="errors[&quot;answers[0].raw_content&quot;]"
          message="can't be blank"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TinymceEditor from '@tinymce/tinymce-vue'
import { mixin as clickaway } from 'vue-clickaway2'
import tinymce from '../../../../mixins/tinymce.js'
import date from '../../../../mixins/date'
import { mapGetters } from 'vuex'
export default {
  components: {
    TinymceEditor
  },
  mixins: [
    date,
    clickaway,
    tinymce
  ],
  data () {
    return {
      editingFrontCard: false,
      editingBackCard: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors',
      'canWriteToAsset',
      'currentUser'
    ])
  },
  methods: {
    clickedFront (ev) {
      if (this.canWriteToAsset) {
        this.frontCardToggle(ev)
      } else if (this.currentUser.permissions.can_write_change_requests) {
        this.$modal.show('change-request-modal', {
          currentContent: this.asset.question,
          assetId: this.asset.id,
          assetType: 'V3Flashcard',
          assetColumn: 'question'
        })
      }
    },
    clickedBack (ev) {
      if (this.canWriteToAsset) {
        this.backCardToggle(ev)
      } else if (this.currentUser.permissions.can_write_change_requests) {
        this.$modal.show('change-request-modal', {
          currentContent: this.asset.choices[0].raw_content,
          assetId: this.asset.choices[0].id,
          assetType: 'Answer',
          assetColumn: 'raw_content'
        })
      }
    },
    frontCardToggle (ev) {
      if (!ev) return
      if (ev.target.className.includes('mce') || ev.target.id.includes('mce')) {
        return
      }
      this.editingFrontCard = !this.editingFrontCard
      this.setCharPosition(ev, 'front-card-editor')
    },
    backCardToggle (ev) {
      if (!ev) return
      if (ev.target.className.includes('mce') || ev.target.id.includes('mce')) {
        return
      }
      this.editingBackCard = !this.editingBackCard
      this.setCharPosition(ev, 'back-card-editor')
    }
  }
}
</script>

<style lang="scss">
.flipcards-container {
  display: flex;
  .flipcard-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    .flipcard-front {
      margin-bottom: 36px;
    }
    .flipcard-front, .flipcard-back {
      flex: 50%;
    }
    .flipcard-label {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 2rem;
    }
    .flipcard {
      margin-right: 20px;
      border-radius: 4px;
      border: 1px solid $space-gray;
      background-color: #ffffff;

      .front-card-section, .back-card-section {
        padding: 30px;
      }
      &:hover {
        border: 1px solid $blue-3;
        cursor: pointer;
      }
      &.selected {
        box-shadow: 0 0 10px 0 $blue-3;
        border: 1px solid #4fb9de;
        -webkit-box-shadow: 0px 0px 3px 3px $blue-5;
        box-shadow: 0px 0px 3px 3px $blue-5;
      }
    }
    .flipcard-edit {
      position:relative;
      z-index: 998;
      .mce-container {
        background: white;
      }
    }
  }
}
</style>
