<template>
  <div class="matrix-grid-container">
    <label>Columns in Grid</label>
    <input class="matrix_grid_columns" type="number" min="1" onKeyDown="return false" v-model="asset.matrix_grid_columns" @change="updateMatrix" />

    <div class="grid">
      <div class="headers">
        <div class="header" v-for="index in parseInt(asset.matrix_grid_columns)" :key="'grid-header-' + index">
          <input type="text" v-model="asset.matrix_grid_column_headers[index - 1]" />
        </div>
      </div>
      <div class="answers">
        <div class="answer" v-for="answer in choices" :key="'answer-' + answer.id">
          <div class="content" v-html='answer.raw_content'>
          </div>
          <div class=""
            v-for="(i, index) in parseInt(asset.matrix_grid_columns - 1)"
            :key="'answer-' + answer.id + '-column-' + index"
          >
            <input type="checkbox" v-model="answer.matrix_grid_correct[index]" >
          </div>
        </div>
      </div>
    </div>

    <multiple-choice />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MultipleChoice from './MultipleChoice.vue'

export default {
  components: {
    MultipleChoice
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['asset']),
    choices () {
      if (this.asset.choices) {
        return this._.sortBy(this.asset.choices, ['sort_order'])
      } else {
        return []
      }
    }
  },
  created () {
  },
  methods: {
    updateMatrix () {
      if (this.asset.matrix_grid_columns < this.asset.matrix_grid_column_headers.length) {
        // remove headers
        this.asset.matrix_grid_column_headers = this.asset.matrix_grid_column_headers.slice(0, this.asset.matrix_grid_columns)
        this.asset.choices.forEach(choice => {
          choice.matrix_grid_correct = choice.matrix_grid_correct.slice(0, this.asset.matrix_grid_columns - 1)
        })
      } else if (this.asset.matrix_grid_columns > this.asset.matrix_grid_column_headers.length) {
        // add headers
        while (this.asset.matrix_grid_columns - this.asset.matrix_grid_column_headers.length > 0) {
          this.asset.matrix_grid_column_headers.push('Column ' + (this.asset.matrix_grid_column_headers.length + 1))
          this.asset.choices.forEach(choice => {
            choice.matrix_grid_correct.push(false)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.matrix-grid-container {
  label {
    font-weight: 700;
  }
  .grid {
    border: 1px solid $space-gray;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;

    .headers {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $space-gray;
      font-weight: 700;
      .header {
        display: flex;
        border-right: 1px solid $space-gray;
        flex-grow: 1;

        &:last-of-type {
          border-right: none;
        }
        input {
          border: 0px;
          margin: 0px;
          text-align: center;
          padding: 0px;
          font-weight: 700;
        }
      }
      .header:first-of-type {
        width: 500px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    .answers {
      .answer {
        display: flex;
        border-bottom: 1px solid $space-gray;
        &:last-of-type {
          border-bottom: none;
        }
        div {
          text-align: center;
          border-right: 1px solid $space-gray;
          flex-grow: 1;
          &:last-of-type {
            border-right: none;
          }
        }
        input {
          margin: 10px;
        }
        .content {
          width: 500px;
          flex-grow: 0;
        }
      }
    }
  }
}
</style>
