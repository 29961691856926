<template>
  <div
    v-if="choice"
    class="choice"
  >
    <div
      class="content"
      @click="clickContent"
    >
      <label for="" class="weight-600">Answer choice</label>
      <div
        v-if="editingContent"
        class="editing"
      >
        <tinymce-editor
          ref="content-editor"
          v-model="choice.raw_content"
          v-on-clickaway="contentToggle"
          :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize']}, tinymceFullConfig)"
          use-custom-image-handler
          @onInit="setCaretPosition"
        />
      </div>
      <div
        v-if="!editingContent"
        class="preview"
        :class="{'error': errors['answers[' + index + '].raw_content'] }"
      >
        <div class="choice-container">
          <p
            v-if="wysiwyg"
            v-html="choice.raw_content || 'Enter Choice'"
            class="raw-content"
          />
          <textarea
            v-else
            ref="content-editor"
            v-model="choice.raw_content"
            :disabled="!canWriteToAsset"
          />
        </div>
        <error-message
          v-if="errors['answers[' + index + '].raw_content']"
          message="can't be blank"
        />
      </div>
      <div class="change-request-button-container">
        <change-request-button
          v-if="assignment"
          :asset-id="choice.id"
          asset-type="Answer"
          context="raw_content"
          :original="choice.raw_content || ''"
          class="change-request-button"
          :wysiwyg='wysiwyg'
        />
      </div>
    </div>


    <div
      class="content"
      @click="clickContent"
    >
      <label for="" class="weight-600">Draft Answer choice</label>
      <div
        v-if="editingContent"
        class="editing"
        style="outline: 2px solid #FCDF5F;"
      >
        <tinymce-editor
          ref="draft-content-editor"
          v-model="choice.draft_text"
          v-on-clickaway="contentToggle"
          :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize']}, tinymceFullConfig)"
          use-custom-image-handler
          @onInit="setCaretPosition"
        />
      </div>
      <div
        v-if="!editingContent"
        class="preview"
        :class="{'error': errors['answers[' + index + '].draft_text'] }"
      >
        <div class="choice-container">
          <p
            v-if="wysiwyg"
            v-html="choice.draft_text"
            class="raw-content"
          />
          <textarea
            v-else
            ref="draft-content-editor"
            v-model="choice.draft_text"
            :disabled="!canWriteToAsset"
          />
        </div>
        <error-message
          v-if="errors['answers[' + index + '].raw_content']"
          message="can't be blank"
        />
      </div>
      <div class="change-request-button-container">
        <change-request-button
          v-if="assignment"
          :asset-id="choice.id"
          asset-type="Answer"
          context="raw_content"
          :original="choice.raw_content || ''"
          class="change-request-button"
          :wysiwyg='wysiwyg'
        />
      </div>
    </div>



    <div
      class="rationale"
      @click="clickRationale"
      v-if="!hideCSR"
    >
      <label for="" class="weight-600">Choice-specific Rationale</label>
      <div
        v-if="editingRationale"
        class="editing"
      >
        <tinymce-editor
          ref="rationale-editor"
          v-model="choice.raw_rationale"
          v-on-clickaway="rationaleToggle"
          :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize']}, tinymceFullConfig)"
          use-custom-image-handler
          @onInit="setCaretPosition"
        />
      </div>
      <div
        v-if="!editingRationale"
        :class="{'no-data': !choice.raw_rationale }"
        class="preview"
      >
        <div class="rationale-container">
          <p
            v-html="choice.raw_rationale || 'Enter Choice-specific Rationale'"
            class="raw-content">
          </p>
        </div>
      </div>
      <div class="change-request-button-container">
        <change-request-button
          v-if="assignment"
          :asset-id="choice.id"
          asset-type="Answer"
          context="raw_rationale"
          :original="choice.raw_rationale || ''"
          class="change-request-button"
          :wysiwyg='wysiwyg'
        />
      </div>
    </div>


    <div
      class="rationale"
      @click="clickRationale"
      v-if="!hideCSR"
    >
      <label for="" class="weight-600">Draft Choice-specific Rationale</label>
      <div
        v-if="editingRationale"
        class="editing"
        style="outline: 2px solid #FCDF5F;"
      >
        <tinymce-editor
          ref="draft-rationale-editor"
          v-model="choice.draft_rationale"
          v-on-clickaway="rationaleToggle"
          :init="tinymceConfig({plugins: ['code', 'image', 'table', 'lists', 'autoresize']}, tinymceFullConfig)"
          use-custom-image-handler
          @onInit="setCaretPosition"
        />
      </div>
      <div
        v-if="!editingRationale"
        :class="{'no-data': !choice.draft_rationale }"
        class="preview"
      >
        <div class="rationale-container">
          <p
            v-html="choice.draft_rationale"
            class="raw-content">
          </p>
        </div>
      </div>
      <div class="change-request-button-container">
        <change-request-button
          v-if="assignment"
          :asset-id="choice.id"
          asset-type="Answer"
          context="draft_rationale"
          :original="choice.draft_rationale || ''"
          class="change-request-button"
          :wysiwyg='wysiwyg'
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TinymceEditor from '@tinymce/tinymce-vue'
import ChangeRequestButton from '../../../shared/page_components/ChangeRequestButton.vue'
import tinymce from '../../../../../mixins/tinymce.js'
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  components: {
    TinymceEditor,
    ChangeRequestButton
  },
  mixins: [
    clickaway,
    tinymce
  ],
  props: {
    choice: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true,
    },
    hideKey: {
      type: Boolean
    },
    hideCSR: {
      type: Boolean,
      default: false
    },
    wysiwyg: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editingContent: false,
      editingRationale: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'errors',
      'canWriteToAsset',
      'currentUser',
      'assignment'
    ])
  },
  watch: {
  editingContent(newValue) {
    if (newValue === true) {
      this.$nextTick(() => {
        this.$refs['content-editor'].editor.focus();
      });
    }
  }
},
  methods: {
    clickContent (ev) {
      if (this.canWriteToAsset) {
        this.contentToggle(ev)
      }
    },
    clickRationale (ev) {
      if (this.canWriteToAsset) {
        this.rationaleToggle(ev)
      }
    },
    contentToggle (ev) {
      if(this.wysiwyg) {
        if (!this.insideTinyMCE(ev, 'content-editor')) {
          this.editingContent = !this.editingContent
          // this.setCharPosition(ev, 'content-editor')
        }
      }
    },
    rationaleToggle (ev) {
      if (!this.insideTinyMCE(ev, 'rationale-editor')) {
        this.editingRationale = !this.editingRationale
        this.setCharPosition(ev, 'rationale-editor')
      }
    },

    ...mapActions(['edit', 'update', 'delete', 'apiItemsById', 'copyToClipboard']),
    ...mapMutations(['setAssetType'])
  }
}
</script>
<style lang="scss" scoped>
.choice {
  background-color: $cloud-white;
  padding-bottom: 20px;
  .content,
  .rationale {
    padding: 20px 32px 0;
    color: $blue-2;
    background-color: $cloud-white;

    .editing {
      border: 1px solid $blue-4;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      box-shadow: 0px 0px 3px 3px $drop-shadow-blue;
    }

    .no-data {
      opacity: 0.5;
    }

    .preview {
      background-color: $cloud-white;
      .choice-container {
        p {
          background: $white;
          padding: 9px 10px;
          border: 1px solid $space-gray;
          border-radius: 4px;
        }
      }
      .rationale-container {
        p {
          background: $white;
          padding: 9px 10px 50px;
          border: 1px solid $space-gray;
          border-radius: 4px;
        }
      }
    }
  }

  .content {
    .editing {
      border: 1px solid $blue-4;
      box-shadow: 0px 0px 3px 3px $drop-shadow-blue;
      textarea {
        margin-bottom: 0px;
      }
    }
  }

  .change-request-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .change-request-button {
      align-self: flex-end;
    }
  }
}
</style>
