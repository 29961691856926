<template>
  <div class="numeric-entry">
    <div class="numeric">
      <label class="weight-600">Correct Answer</label>
      <input
        v-model="numeric"
        type="text"
        class="hlt-input"
      >
    </div>
    <div class="unit">
      <label class="weight-600">Unit</label>
      <input
        v-model="unit"
        type="text"
        class="hlt-input"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      numeric: '',
      unit: ''
    }
  },
  computed: {
    ...mapGetters(['asset'])
  },
  watch: {
    numeric (val, oldVal) {
      var json = JSON.parse(this.asset.choices[0].text)
      json.unit = this.unit
      json.numeric = val

      // Another issue with updating an array not working as expecting now

      this.asset.choices[0].raw_content = JSON.stringify(json)
      this.asset.choices[0].text = JSON.stringify(json)
    },
    unit (val, oldVal) {
      var json = JSON.parse(this.asset.choices[0].text)
      json.unit = val
      json.numeric = this.numeric
      this.asset.choices[0].raw_content = JSON.stringify(json)
      this.asset.choices[0].text = JSON.stringify(json)
    }
  },
  created () {
    this.numeric = JSON.parse(this.asset.choices[0].text).numeric
    this.unit = JSON.parse(this.asset.choices[0].text).unit
  }
}
</script>
<style lang="scss" scoped>
.numeric-entry {
  .numeric, .unit {
    color: $blue-2;
    width: 20%;
    display: inline-block;
    margin-right: 20px;
  }
}
</style>
