<template>
  <div class="hotspot">
    <label class="weight-700">Attachment ID</label>
    <div class="attachment-id-container">
      <input type="text" v-model='hotspot.attachment_id' />
      <button class="button hlt-button secondary" @click="refreshPreview">Refresh Preview</button>
    </div>
    <div class="preview-container" v-if="previewUrl">
      <cropper
        ref="hotspot"
        :src="previewUrl"
        class="preview"
        alt="Hotspot Attachment"
        @change="onCrop"
        :default-position="hotspotPosition"
        :default-size="hotspotSize"
        :resizeImage="false"
        />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper
  },
  data () {
    return {
      previewUrl: null
    }
  },
  computed: {
    ...mapGetters(['asset']),
    hotspot () {
      return this.asset.hotspots[0]
    },
    hotspotPosition () {
      if (this.hotspot.x1) {
        return {
          left: this.hotspot.x1,
          top: this.hotspot.y1
        }
      } else {
        return {
          left: 100,
          top: 100
        }
      }
    },
    hotspotSize () {
      if (this.hotspot.x1) {
        return {
          width: this.hotspot.x2 - this.hotspot.x1,
          height: this.hotspot.y2 - this.hotspot.y1
        }
      } else {
        return {
          width: 200,
          height: 200
        }
      }
    }
  },
  created () {
    this.previewUrl = this.hotspot.attachment.large_content_url
  },
  methods: {
    ...mapActions([
      'apiAttachmentsById'
    ]),
    onCrop ({ coordinates, imageTransforms, visibleArea, canvas }) {
      this.hotspot.x1 = coordinates.left
      this.hotspot.y1 = coordinates.top
      this.hotspot.x2 = coordinates.left + coordinates.width
      this.hotspot.y2 = coordinates.top + coordinates.height
      this.hotspot.width = visibleArea.width
    },
    refreshPreview () {
      if(this.hotspot.attachment_id) {
        this.apiAttachmentsById({ attachmentId: this.asset.hotspots[0].attachment_id }).then(response => {
          this.previewUrl = response.data.records[0].large_content_url
        }).catch(error => {
          console.error('Error fetching attachment', error)
          this.previewUrl = null
        })
      } else {
        this.previewUrl = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot {
  padding: 10px;
  .attachment-id-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 400px;
    button {
      width: 300px;
      padding: 5px 10px;
      margin-top: 0px;
    }
  }
  .preview {
    border: 1px solid $space-gray;
    width: 600px;
    cursor: pointer;
  }
}
</style>
