export default {
  data () {
    return {
      textContent: '',
      caretPosition: '',
      isEditing: {},
      extended_valid_elements: 'img[class=hlt-image|src|width|height|alt|title|name|style]',
      tinymceFullConfig: {
        force_br_newlines: false,
        force_p_newlines: true,
        a11y_advanced_options: true,
        extended_valid_elements: this.extended_valid_elements,
        skin: false,
        branding: false,
        browser_spellcheck: true,
        verify_html: false,
        menubar: false,
        statusbar: false,
        toolbar:
          'undo redo | bold italic underline | typeShift | styleselect | alignment | bullist numlist | outdent indent | image | table | code | clear',
        setup: this.tinymceFix
      },
      tinymceSmallConfig: {
        force_br_newlines: false,
        force_p_newlines: true,
        a11y_advanced_options: true,
        extended_valid_elements: this.extended_valid_elements,
        skin: false,
        branding: false,
        browser_spellcheck: true,
        menubar: false,
        statusbar: false,
        verify_html: false,
        toolbar:
          'undo redo | bold italic underline | styleselect | code | clear',
        setup: this.tinymceFix
      }
    }
  },

  methods: {
    insideTinyMCE (ev, name) {
      if (this.$refs[name]) {
        if (this.shortCircuit(ev)) return true
      }
      return false
    },
    shortCircuit (ev) {
      return (
        !ev ||
        document.getElementsByClassName('mce-window').length > 0 || // Short circuit if the view source window is open (Chrome specific hack)
        ev.target.className.includes('mce') ||
        ev.target.id.includes('mce')
      )
    },
    editToggle (ev, ref) {
      const self = this
      if (self.draftMode) {
        if (self.$refs[ref]) {
          if (this.shortCircuit(ev)) return true
        }
        self.setCharPosition(ev)
        self.isEditing[ref] = !self.isEditing[ref]
      }
    },
    tinymceConfig (params, config) {
      return Object.assign({}, config, params)
    },
    tinymceFix (ed) {
      ed.on('init', function () {
        this.getDoc().body.style.color = '#1d2a33'
        this.getDoc().body.style.fontSize = '12'
        this.getDoc().body.style.fontFamily = 'Arial, Helvetica, sans-serif'
        ed.focus()
      })
      ed.addButton('clear', {
        text: 'Clear',
        tooltip: 'Clear formatting from section',
        onclick: function () {
          ed.execCommand('RemoveFormat')
          this.parent().parent().value = 'RemoveFormat'
        }
      })
      ed.addButton('alignment', {
        type: 'splitbutton',
        text: '',
        icon: 'alignleft',
        menu: [
          {
            icon: 'alignleft',
            text: 'Align Left',
            onclick: function () {
              ed.execCommand('JustifyLeft')
              this.parent().parent().value = 'JustifyLeft'
            }
          },
          {
            icon: 'alignright',
            text: 'Align Right',
            onclick: function () {
              ed.execCommand('JustifyRight')
              this.parent().parent().value = 'JustifyRight'
            }
          },
          {
            icon: 'aligncenter',
            text: 'Align Center',
            onclick: function () {
              ed.execCommand('JustifyCenter')
              this.parent().parent().value = 'JustifyCenter'
            }
          },
          {
            icon: 'alignjustify',
            text: 'Justify',
            onclick: function () {
              ed.execCommand('JustifyFull')
              this.parent().parent().value = 'JustifyFull'
            }
          }
        ]
      })
      ed.addButton('typeShift', {
        type: 'splitbutton',
        text: 'Tt',
        menu: [
          {
            icon: 'strikethrough',
            text: 'Strikethrough',
            onclick: function () {
              ed.execCommand('Strikethrough')
              this.parent().parent().value = 'Strikethrough'
            }
          },
          {
            icon: 'superscript',
            text: 'Superscript',
            onclick: function () {
              ed.execCommand('Superscript')
              this.parent().parent().value = 'Superscript'
            }
          },
          {
            icon: 'subscript',
            text: 'Subscript',
            onclick: function () {
              ed.execCommand('Subscript')
              this.parent().parent().value = 'Subscript'
            }
          }
        ]
      })
    },
    setCaretPosition (ed) {
      const textnodes = this.getTextNodes(ed.target.getBody(), this.textContent)
      // set Cursor to last position
      ed.target.selection.setCursorLocation(
        textnodes[textnodes.length - 1],
        this.caretPosition
      )
    },
    setCharPosition (ev) {
      const charPosition = this.getCaretPosition(ev.target)
      this.caretPosition = charPosition.caretPos
      if (charPosition.range && charPosition.range.startContainer) {
        this.textContent = charPosition.range.startContainer.textContent
      }
    },
    getCaretPosition (editableDiv) {
      let caretPos = 0
      let sel
      let range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.rangeCount) {
          range = sel.getRangeAt(0)
          if (range.commonAncestorContainer.parentNode == editableDiv) {
            caretPos = range.endOffset
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange()
        if (range.parentElement() == editableDiv) {
          var tempEl = document.createElement('span')
          editableDiv.insertBefore(tempEl, editableDiv.firstChild)
          var tempRange = range.duplicate()
          tempRange.moveToElementText(tempEl)
          tempRange.setEndPoint('EndToEnd', range)
          caretPos = tempRange.text.length
        }
      }
      return { range: range, caretPos: caretPos }
    },
    getTextNodes (node, value, result) {
      const children = node.childNodes
      result = !result ? [] : result
      if (node.textContent == value) {
        result.push(node)
      }
      for (let i = 0; i < children.length; i++) {
        result = this.getTextNodes(children[i], value, result)
      }
      return result
    }
  }
}
