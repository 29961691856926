<template>
  <div class="multiple-choice">
    <div
      class="toggle-wrapper"
    >
      <h4 class="hlt-header h4 weight-600">Choices</h4>
    </div>

    <draggable
      v-model="choices"
      handle=".order-box-drag"
      :group="choices"
      class="draggable"
    >
      <transition-group>
        <order-box
          v-for="(choice, index) in choices"
          :key="'choice-' + index"
          :order-object="choice"
          :order-object-id="choice.id"
          order-object-type="Choice"
          :index="index"
          :draft-mode="!discussionMode"
          :box-styles="boxStyles"
          :isDeletable="isDeletable"
          :isMovable="isMovable"
        >
          <template v-slot:headerTitle>
            <span :title="'choice id: ' + choice.id">{{ highlightText(choice) }}</span>
          </template>

          <template v-slot:headerExtras>
            <div class="choice-key" v-if='asset.type != "MatrixGrid"'>
              <hlt-toggle
                class="choice-key-button"
                :value="choice.correct"
                :labels="{
                  checked: 'Key',
                  unchecked: 'Key'
                }"
                style-type="small"
                @change="toggleCorrect(choice)"
              />
            </div>
          </template>

          <template v-slot:content>
            <choice
              :choice="choice"
              :index="index"
            />
          </template>
        </order-box>
      </transition-group>
    </draggable>

    <delete-order-box-modal @orderBoxDeletion="deleteChoice" />
    <button
      v-if="canWriteToAsset"
      class="hlt-button secondary add_choice_button"
      @click="addChoice()"
    >
      Add Choice
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import Choice from '@/components/assets/items/item_types/shared/Choice.vue'
import randomize from '@/mixins/randomize-choices'
import OrderBox from '@/components/shared/OrderBox.vue'
import DeleteOrderBoxModal from '@/components/shared/DeleteOrderBoxModal.vue'
import HltToggle from '@/components/assets/shared/page_components/Toggle.vue'

export default {
  components: {
    Choice,
    Draggable,
    OrderBox,
    DeleteOrderBoxModal,
    HltToggle
  },
  mixins: [randomize],
  data () {
    return {
      boxStyles: {
        header: [],
        content: ['no-padding']
      }
    }
  },
  computed: {
    isMovable () {
      return !(this.assignment || !this.currentUser.permissions.can_write_flashcards)
    },
    isDeletable () {
      return !(this.assignment || !this.currentUser.permissions.can_write_flashcards)
    },
    sortedActiveChoices () {
      return this._.sortBy(this.asset.choices.filter(x => !x.deleted), ['sort_order'])
    },
    choices: {
      get () {
        if (this.asset.choices) {
          // Don't filter out deleted because
          // if you then reorder, the setter resets choices to only the current active choices
          // and then the deleted choice won't get saved as deleted
          // Instead, OrderBox has a conditional to hide the deleted in the UI
          return this._.sortBy(this.asset.choices, ['sort_order'])
        } else {
          return []
        }
      },
      set (value) {
        if (this.canWriteToAsset) {
          this.asset.choices = this.orderArray(value, 'sort_order')
        }
      }
    },
    ...mapGetters([
      'asset',
      'canWriteToAsset',
      'discussionMode',
      'assignment',
      'currentUser'
    ])
  },
  created () {
    this.setRandomize()
    this.asset.choices = this._.sortBy(this.asset.choices, ['sort_order'])
  },
  methods: {
    highlightText (choice) {
      let index = this.sortedActiveChoices.indexOf(choice) + 1
      return `{{highlight-${index}}}`
    },
    choiceId (choice) {
      if (choice.id) return choice.id
      return ''
    },
    addChoice () {
      let maxIndex = 0
      if (!this.randomized && this.asset.choices.length > 0) {
        maxIndex = Math.max.apply(Math, this.asset.choices.map((o) => { return o.sort_order }))
      }
      let matrixGridCorrect = []
      if (this.asset.matrix_grid_columns) {
        matrixGridCorrect = new Array(this.asset.matrix_grid_columns - 1).fill(false)
      }
      this.asset.choices.push({
        deleted: false,
        correct: false,
        sort_order: maxIndex + 1,
        matrix_grid_correct: matrixGridCorrect
      })
    },
    deleteChoice (params) {
      const localChoices = this.choices
      if (localChoices[params.index].id === undefined) {
        localChoices.splice(params.index, 1)
      } else {
        localChoices[params.index].deleted = true
      }
      this.choices = localChoices
    },
    toggleCorrect (choice) {
      if (this.canWriteToAsset) {
        choice.correct = !choice.correct
      }
    }
  }
}
</script>
<style lang="scss">
.toggle-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 16px;
  h4 {
    margin: 0;
    padding: 0;
  }
  .randomize-button {
    align-self: center;
    padding: 0;
    margin: 0;
  }
}
.multiple-choice {
  .order-box .header {
    background: $cloud-white;
  }
  .draggable {
    margin-top: 10px;
  }
}
.choice-key {
  margin: 0 10px 3px;
}
</style>
