<template>
  <div class="blank-text-completion">
    <div class="blank-text-header">
      <div class="blank-text-row">
        <div class="blank-text-header-section">
          <label class="answer-label weight-600">Correct Answer</label>
          <label class="helper-label">Select the fields</label>
        </div>
        <div class="blank-text-header-section">
          <div class="dimension-selection">
            <div class="field-tab">
              <input
                v-model="dimensions"
                type="radio"
                name="dimensions33"
                value="3x3"
              >
              <label
                for="dimensions33"
                class="dimensions-3-3"
                @click="showDimensionsFields('3x3')"
              >
                3 X 3
              </label>
            </div>
            <div class="field-tab">
              <input
                v-model="dimensions"
                type="radio"
                name="dimensions23"
                value="2x3"
              >
              <label
                for="dimensions23"
                class="dimensions-2-3"
                @click="showDimensionsFields('2x3')"
              >
                2 x 3
              </label>
            </div>
            <div class="field-tab">
              <input
                v-model="dimensions"
                type="radio"
                name="dimensions32"
                value="3x2"
              >
              <label
                for="dimensions32"
                class="dimensions-3-2"
                @click="showDimensionsFields('3x2')"
              >
                3 x 2
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="dimensions == '3x3'"
      class="blank-text-table dimension3x3"
    >
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="0"
          >
          <input
            v-model="choices[0].raw_content"
            type="text"
            :class="[{'error': errors['answers[0].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[0].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="1"
          >
          <input
            v-model="choices[1].raw_content"
            type="text"
            :class="[{'error': errors['answers[1].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[1].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="2"
          >
          <input
            v-model="choices[2].raw_content"
            type="text"
            :class="[{'error': errors['answers[2].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[2].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
      <div class="rule"></div>
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="3"
          >
          <input
            v-model="choices[3].raw_content"
            type="text"
            :class="[{'error': errors['answers[3].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[3].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="4"
          >
          <input
            v-model="choices[4].raw_content"
            type="text"
            :class="[{'error': errors['answers[4].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[4].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="5"
          >
          <input
            v-model="choices[5].raw_content"
            type="text"
            :class="[{'error': errors['answers[5].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[5].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
      <div class="rule"></div>
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row3"
            type="radio"
            name="row3"
            value="6"
          >
          <input
            v-model="choices[6].raw_content"
            type="text"
            :class="[{'error': errors['answers[6].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[6].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row3"
            type="radio"
            name="row3"
            value="7"
          >
          <input
            v-model="choices[7].raw_content"
            type="text"
            :class="[{'error': errors['answers[7].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[7].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row3"
            type="radio"
            name="row3"
            value="8"
          >
          <input
            v-model="choices[8].raw_content"
            type="text"
            :class="[{'error': errors['answers[8].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[8].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
    </div>

    <div
      v-if="dimensions == '2x3'"
      class="blank-text-table dimension2x3"
    >
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="0"
          >
          <input
            v-model="choices[0].raw_content"
            type="text"
            :class="[{'error': errors['answers[0].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[0].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="1"
          >
          <input
            v-model="choices[1].raw_content"
            type="text"
            :class="[{'error': errors['answers[1].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[1].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
      <div class="rule"></div>
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="2"
          >
          <input
            v-model="choices[2].raw_content"
            type="text"
            :class="[{'error': errors['answers[2].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[2].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="3"
          >
          <input
            v-model="choices[3].raw_content"
            type="text"
            :class="[{'error': errors['answers[3].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[3].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
      <div class="rule"></div>
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row3"
            type="radio"
            name="row3"
            value="4"
          >
          <input
            v-model="choices[4].raw_content"
            type="text"
            :class="[{'error': errors['answers[4].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[4].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row3"
            type="radio"
            name="row3"
            value="5"
          >
          <input
            v-model="choices[5].raw_content"
            type="text"
            :class="[{'error': errors['answers[5].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[5].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
    </div>

    <div
      v-if="dimensions == '3x2'"
      class="blank-text-table dimension3x2"
    >
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="0"
          >
          <input
            v-model="choices[0].raw_content"
            type="text"
            :class="[{'error': errors['answers[0].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[0].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="1"
          >
          <input
            v-model="choices[1].raw_content"
            type="text"
            :class="[{'error': errors['answers[1].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[1].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row1"
            type="radio"
            name="row1"
            value="2"
          >
          <input
            v-model="choices[2].raw_content"
            type="text"
            :class="[{'error': errors['answers[2].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[2].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
      <div class="rule"></div>
      <div class="table-row">
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="3"
          >
          <input
            v-model="choices[3].raw_content"
            type="text"
            :class="[{'error': errors['answers[3].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[3].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="4"
          >
          <input
            v-model="choices[4].raw_content"
            type="text"
            :class="[{'error': errors['answers[4].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[4].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="table-column">
          <input
            v-model="row2"
            type="radio"
            name="row2"
            value="5"
          >
          <input
            v-model="choices[5].raw_content"
            type="text"
            :class="[{'error': errors['answers[5].raw_content'] }, 'hlt-input']"
          >
          <error-message
            v-if="errors[&quot;answers[5].raw_content&quot;]"
            message="can't be blank"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dimensions: '',
      row1: 0,
      row2: 3,
      row3: 6
    }
  },
  computed: {
    choices () {
      return this.asset.choices.filter(k => !k.deleted)
    },
    ...mapGetters([
      'asset',
      'errors'
    ])
  },
  watch: {
    row1 (val, oldVal) {
      if ((this.dimensions == '3x3') || (this.dimensions == '3x2')) {
        for (let i = 0; i <= 2; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      } else if (this.dimensions == '2x3') {
        for (let i = 0; i <= 1; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      }
    },
    row2 (val, oldVal) {
      if ((this.dimensions == '3x3') || (this.dimensions == '3x2')) {
        for (let i = 3; i <= 5; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      } else if (this.dimensions == '2x3') {
        for (let i = 2; i <= 3; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      }
    },
    row3 (val, oldVal) {
      if (this.dimensions == '3x3') {
        for (let i = 6; i <= 8; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      } else if (this.dimensions == '2x3') {
        for (let i = 4; i <= 5; i++) {
          this.choices[i].correct = false
        }
        this.choices[val].correct = true
      }
    },
    dimensions (val, oldVal) {
      this.asset.blank_text_columns = parseInt(val.charAt(0))

      if (val == '3x3') {
        if (this.asset.choices.length != 9) {
          for (let i = this.asset.choices.length; i < 9; i++) {
            this.asset.choices.push({
              app_id: this.asset.app_id,
              deleted: false,
              correct: false,
              sort_order: this.asset.choices.length - 1,
              raw_content: `Choice ${i}`
            })
          }
        }

        for (let i in this.asset.choices) {
          this.asset.choices[i].deleted = false
        }
      }
      if ((val == '2x3') || (val == '3x2')) {
        if (this.asset.choices.length < 6) {
          for (let i = this.asset.choices.length; i <= 6; i++) {
            this.asset.choices.push({
              app_id: this.asset.app_id,
              deleted: false,
              correct: false,
              sort_order: this.asset.choices.length - 1,
              raw_content: `Choice ${i}`
            })
          }
        }
        if (this.asset.choices.length > 6) {
          for (let i = this.choices.length - 1; i >= 6; i--) {
            this.choices[i].deleted = true
          }
        }
      }
    }
  },
  created () {
    this.dimensions = this.calculateDimensions()
    this.asset.choices = this.asset.choices.sort((a, b) => {
      return a.sort_order - b.sort_order
    })

    if (this.dimensions == '3x3') {
      for (let i = 0; i <= 2; i++) {
        if (this.choices[i].correct) {
          this.row1 = i
        }
      }

      for (let i = 3; i <= 5; i++) {
        if (this.choices[i].correct) {
          this.row2 = i
        }
      }

      for (let i = 6; i <= 8; i++) {
        if (this.choices[i].correct) {
          this.row3 = i
        }
      }
    } else if (this.dimensions == '3x2') {
      for (let i = 0; i <= 2; i++) {
        if (this.choices[i].correct) {
          this.row1 = i
        }
      }

      for (let i = 3; i <= 5; i++) {
        if (this.choices[i].correct) {
          this.row2 = i
        }
      }
    } else if (this.dimensions == '2x3') {
      for (let i = 0; i <= 1; i++) {
        if (this.choices[i].correct) {
          this.row1 = i
        }
      }

      for (let i = 2; i <= 3; i++) {
        if (this.choices[i].correct) {
          this.row2 = i
        }
      }

      for (let i = 4; i <= 5; i++) {
        if (this.choices[i].correct) {
          this.row3 = i
        }
      }
    }
  },
  methods: {
    showDimensionsFields(val) {
      this.dimensions = val
    },
    calculateDimensions () {
      if (this.asset) {
        return `${this.asset.blank_text_columns}x${this.choices.length / this.asset.blank_text_columns}`
      } else {
        return 'boo'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.blank-text-completion {
  .helper-label {
    color: $space-gray;
  }
  .blank-text-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .dimension-selection {
    display: flex;
    input {
      margin-left: 30px;
      margin-right: 10px;
    }
    .field-tab {
      height: 36px;
      label {
        padding: 5px 15px;
        margin: 0;
        font-size: 85%;
        font-weight: 600;
        border: 1px solid $space-gray;
        color: $space-black-20;
        &.dimensions-3-3 {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right-style: none;
        }
        &.dimensions-3-2 {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left-style: none;
        }
      }
    }

    [type=radio] {
      display: none;
    }

    [type=radio]:checked~label {
      background: $cold-gray;
      z-index: 2;
      color: white;
    }

  }

  .blank-text-table {
    color: $blue-2;
    display: flex;
    flex-direction: column;

    .table-row {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      .table-column {
        width: 400px;

        input[type="text"] {
          width: 80%;
          display: inline-block;
          color: $blue-2;
          margin-bottom: 0px;
        }

        input[type="radio"] {
          margin-right: 10px;
        }

        .error-message {
          margin-left: 70px;
        }
      }
    }
  }
  .rule {
    height: 1px;
    border-top: 1px solid $space-gray-20;
    margin-top: 23px;
    margin-bottom: 24px;
  }
}
</style>
