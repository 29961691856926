<template>
  <div class="simple-text-box">
    <label class="weight-700">{{ title }}
      <span
        class="char-count"
        v-if="showCharCount"
      >
        ({{ charCount }} characters)
      </span>
    </label>
    <div class="simple-text-box-container">
      <textarea
        v-model="asset[elmName]"
        class="form-control"
        :name="name"
        :rows="rows || 1"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    'asset': Object,
    'elm-name': String,
    'form-name': String,
    'title': String,
    'showCharCount': {
      type: Boolean,
      default: true,
    },
    'rows': {
      type: Number,
      default: 1,
    }
  },
  data () {
    return {
      editingQuestion: false
    }
  },
  computed: {
    name() {
      return this.formName || this.elmName;
    },
    charCount() {
      return this.asset[this.elmName]?.length || 0;
    }
  }
}

</script>

<style lang="scss">
.simple-text-box {
  margin-bottom: 25px;
}
.simple-text-box-container {
  border: 1px solid $space-gray;
  background-color: #f6f6f7;
}
.form-control {
  border: none;
  padding: 10px;
  resize: none;
  width: 100%;
}
</style>

